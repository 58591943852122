import firebase from "firebase";

export async function opConvertTime() {
  const db = firebase.firestore();
  const queryForItems = db.collection("checkins");
  const toUpdateKeys: string[] = [];
  const toUpdate: { [key: string]: any } = {};
  const missingDatesKeys: string[] = [];
  const missingDates: { [key: string]: any } = {};

  try {
    const snapshot = await queryForItems.get();

    snapshot.forEach((document) => {
      const key = document.id;
      const data = document.data();

      let originalCreated = data.created;
      if ((originalCreated as firebase.firestore.Timestamp) && (originalCreated as firebase.firestore.Timestamp).toMillis) {
        console.log('Created for ', key, ' is ok.');
      } else if (originalCreated) {
        toUpdateKeys.push(key);
        toUpdate[key] = data;
      } else {
        missingDatesKeys.push(key);
        missingDates[key] = data;
      }

    });
  } catch (error) {
    console.error('Error when querying: ', error);
    return;
  }

  if (toUpdateKeys.length > 0) {
    let i = 0;
    const max = 100;
    for (const key of toUpdateKeys) {
      const data = toUpdate[key];
      const created = firebase.firestore.Timestamp.fromMillis(data.created);
      console.log('Converting', data.created, ' to ', created, ' for key', key, ', data:', data);

      try {
        await db.collection("checkins").doc(key).update({
          created,
        });
        console.info('Doc updated in checkins');
      } catch (error) {
        console.error('Doc is not in checkins');
      }
      try {
        await db.collection("approved").doc(key).update({
          created,
        });
        console.info('Doc updated in approved');
      } catch (error) {
        console.error('Doc is not in approved');
      }
      try {
        await db.collection("rejected").doc(key).update({
          created,
        });
        console.info('Doc updated in rejected');
      } catch (error) {
        console.error('Doc is not in rejected');
      }

      i++;
      if (i >= max) {
        break;
      }
    }
  }

  if (missingDatesKeys.length > 0) {
    const created = firebase.firestore.Timestamp.fromMillis(1590986687986);
    let i = 0;
    const max = 100;
    for (const key of missingDatesKeys) {
      const data = missingDates[key];
      console.log('Setting default date for', key, ', data:', data);

      // Remove from other collections.
      await db.collection("checkins").doc(key).delete();
      await db.collection("approved").doc(key).delete();
      await db.collection("rejected").doc(key).delete();

      // Add to tests collection.
      await db.collection("tests").doc(key).update({
        created,
      });

      i++;
      if (i >= max) {
        break;
      }
    }
  }

  console.log(`There were ${toUpdateKeys.length} incorrect dates and ${missingDatesKeys.length} missing dates.`);
}
