import firebase from "firebase";
import { message, notification } from "antd";

export async function refreshMap() {
  const refreshMapFunction = firebase.functions().httpsCallable('refreshMap');

  const startTime = Date.now()
  const hide = message.loading('Refreshing map...', 0)
  const results: any = await refreshMapFunction();

  const endTime = Date.now()
  const timeTakenInSeconds = (endTime - startTime) / 1000
  hide()
  console.log('[refreshMap] results:', results);
  const successMessage = `${results.data.message || "Map refreshed."} Took ${timeTakenInSeconds.toFixed(2)} seconds.`

  notification.success({
    message: 'Success',
    description: successMessage,
    duration: 0,
  });
}
