import firebase from "firebase";

export async function opUpdateApprovedItems() {
  const db = firebase.firestore();

  const approvedItemsKeys: string[] = [];
  const approvedItems: { [key: string]: any } = {};
  const approvedItemsSnapshot = await db.collection("approved").get();

  approvedItemsSnapshot.forEach((document) => {
    const key = document.id;
    const data = document.data();
    approvedItemsKeys.push(key);
    approvedItems[key] = data;
  });

  for (const key of approvedItemsKeys) {
    const data = approvedItems[key];
    if (data.status === "approved") {
      console.log('Skipping', key, ' already status=approved');
      continue;
    }

    await db.collection("checkins").doc(key).update({
      isReviewed: true,
      status: 'approved',
      approvedBy: data.approvedBy,
      approvedAt: data.approvedAt,
    });
    console.log('Updated', key);
  }
}
