import React from "react"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { auth } from 'firebaseui';
import { Breadcrumb } from 'antd';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  // signInFlow: 'popup',
  signInFlow: 'redirect',
  //Start it here
  credentialHelper: auth.CredentialHelper.NONE,
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
      // console.log('signInSuccessWithAuthResult authResult:', authResult, ', redirectUrl:', redirectUrl);
      return false;
    }
  },
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    }
  ]
};

const Login: React.FC = () => {
  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Login</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <h2>Log in</h2>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    </>
  );
}

export default Login
