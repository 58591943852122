import React, { useState } from "react"
import firebase from 'firebase';
import { Alert, Breadcrumb, Button, Input, message } from 'antd';
import './Operations.scss';
import { refreshStats } from "../operations/refreshStats";
import { opUpdateApprovedItems } from "../operations/opUpdateApprovedItems";
import { opWriteToLocalStorage } from "../operations/opWriteToLocalStorage";
import { opConvertTime } from "../operations/opConvertTime";
import { opWriteApprovedToLocalStorage } from "../operations/opWriteApprovedToLocalStorage";
import { opWriteApprovedToStoreV2 } from "../operations/opWriteApprovedToStoreV2";
import { opWriteApprovedToSingleFile } from "../operations/opWriteApprovedToSingleFile";
import { opAssignRandomBuckets } from "../operations/opAssignRandomBuckets";
import { opMoveSkippedToPending } from "../operations/opMoveSkippedToPending";
import { opDownloadApprovedWithMetadata } from "../operations/opDownloadApprovedWithMetadata";
import { refreshMap } from "../operations/refreshMap";
import { rejectDot } from "../operations/rejectDot";
import { archive2020 } from "../operations/archive2020";

export function download(content: string, fileName: string, contentType: string) {
  const a = document.createElement("a");
  const file = new Blob([content], {type: contentType});
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}


// Not to be called directly from UI.
export const getAllApprovedItems = async () => {
  const db = firebase.firestore();
  const newItems: any[] = [];

  const queryForItems = db.collection("checkins").where("status", "==", "approved");
  try {
    const snapshot = await queryForItems.get();

    snapshot.forEach((document) => {
      const key = document.id;
      const data = document.data();

      let created = data.created;
      if ((created as firebase.firestore.Timestamp) && (created as firebase.firestore.Timestamp).toMillis) {
        created = (created as firebase.firestore.Timestamp).toMillis();
      }

      const location = data.location;
      const lat = location[0].toFixed(5);
      const lng = location[1].toFixed(5);

      const newItem = {
        n: data.name,
        m: data.message,
        d: `${key}|${lat}|${lng}|${data.color}|${created}`,
      };
      newItems.push(newItem);
    });

  } catch (error) {
    console.error('Error when querying: ', error);
    throw error;
  }

  return newItems;
}

const Operations: React.FC = () => {

  const [dotKey, setDotKey] = useState<string>("");

  const handleRefreshStats = async () => {
    await refreshStats();
  }

  const handleUpdateApprovedItems = async () => {
    await opUpdateApprovedItems();
  }

  const writeToLocalStorage = async () => {
    await opWriteToLocalStorage();
  }

  const handleConvertTime = async () => {
    await opConvertTime();
  }

  const writeApprovedToLocalStorage = async () => {
    await opWriteApprovedToLocalStorage();
  }

  const writeApprovedToStoreV2 = async () => {
    await opWriteApprovedToStoreV2();
  }

  const writeApprovedToSingleFile = async () => {
    await opWriteApprovedToSingleFile();
  }

  const assignRandomBuckets = async () => {
    await opAssignRandomBuckets();
  };

  const moveSkippedToPending = async () => {
    await opMoveSkippedToPending();
  };

  const handleSwitchToCheckin = () => {
    firebase.firestore().collection("stats").doc("status").update({
      screen: "checkin",
    });
  }

  const handleSwitchToHolding = () => {
    firebase.firestore().collection("stats").doc("status").update({
      screen: "holding",
    });
  }

  const handleSwitchToMap = () => {
    firebase.firestore().collection("stats").doc("status").update({
      screen: "map",
    });
  }

  const downloadApprovedWithMetadata = async () => {
    await opDownloadApprovedWithMetadata();
  }

  const handleRefreshMap = async () => {
    await refreshMap();
  }

  const handleArchive2020 = async () => {
    await archive2020();
  }

  // Dot-specific
  const handleRejectDot = async () => {
    if (!dotKey) {
      message.error(`No key specified`);
      return;
    }

    await rejectDot(dotKey);

    setDotKey("");
  };

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Operations</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <h2>Operations</h2>
        <Alert
          message="DO NOT use these unless you absolutely understand what you are doing"
          type="error"
          style={{ marginBottom: 32 }}
        />
        <Button className="operation-button" onClick={handleRefreshStats}>Refresh stats (runs on server)</Button>
        <Button className="operation-button" onClick={handleUpdateApprovedItems}>Update approved items</Button>
        <Button className="operation-button" onClick={handleConvertTime}>Convert time</Button>
        <Button className="operation-button" onClick={writeToLocalStorage}>Write to localStorage</Button>
        <Button className="operation-button" onClick={writeApprovedToLocalStorage}>Write approved to localStorage</Button>
        <Button className="operation-button" onClick={assignRandomBuckets}>Assign random buckets</Button>
        <Button className="operation-button" onClick={writeApprovedToStoreV2}>Write approved to Firestore</Button>
        <Button className="operation-button" onClick={writeApprovedToSingleFile}>Download approved json (for web to consume)</Button>
        <Button className="operation-button" onClick={moveSkippedToPending}>Move skipped to pending</Button>
        <Button className="operation-button" onClick={downloadApprovedWithMetadata}>Download approved with metadata</Button>
        <Button className="operation-button" onClick={handleRefreshMap}>Refresh map (runs on server)</Button>
        {/*<Button className="operation-button" onClick={handleArchive2020}>Archive 2020</Button>*/}

        <h2 style={{ marginTop: 32 }}>Dot-specific operations</h2>
        <div>
          <Input
            value={dotKey}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDotKey(event.target.value)}
            placeholder="Key"
          />
          <Button className="operation-button" onClick={handleRejectDot}>Reject</Button>


        </div>

        <h2 style={{ marginTop: 32 }}>Screen switch</h2>
        <Button className="operation-button" onClick={handleSwitchToCheckin}>Switch to checkin</Button>
        <Button className="operation-button" onClick={handleSwitchToHolding}>Switch to holding</Button>
        <Button className="operation-button" onClick={handleSwitchToMap}>Switch to map</Button>
      </div>

    </div>
  );
};

export default Operations;
