import { getAllApprovedItemsInBatches } from "./opGetAllApprovedItemsInBatches";
import { notification } from "antd";
import moment from "moment";
import { download } from "../screens/Operations";

export async function opWriteApprovedToSingleFile() {
  let batches;
  try {
    batches = await getAllApprovedItemsInBatches();
  } catch (error) {
    console.error('Error from getAllApprovedItemsInBatches: ', error);
    notification.error({
      message: `Could not get approved items in batches`,
      description: 'Check console for more details',
      duration: 0,
    });
    return;
  }

  // Write to localStorage
  const content = JSON.stringify(batches);
  window.localStorage.setItem('approved', content);

  // Download to file since we already have this data.
  const timeSuffix = moment().format("YYYYMMDD_HHmm");
  download(content, `approved_${timeSuffix}.json`, 'text/plain');
}
