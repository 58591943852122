import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  // useHistory,
} from "react-router-dom";
import firebase from 'firebase';
import { Layout, Menu, Button, Breadcrumb } from 'antd';

import './App.scss';
import Login from './screens/Login';
import Moderate from './screens/Moderate';
import Operations from './screens/Operations';
import Home from './screens/Home';
import NoAccess from './screens/NoAccess';
import Search from './screens/Search';
// import FailsAnalysis from './screens/FailsAnalysis';

const firebaseConfig = {
  apiKey: "AIzaSyDHEtoMuNKUfPfUhcZggd9Z3peAgf-hWgo",
  authDomain: "pinkdot2020-1bffa.firebaseapp.com",
  databaseURL: "https://pinkdot2020-1bffa.firebaseio.com",
  projectId: "pinkdot2020-1bffa",
  storageBucket: "pinkdot2020-1bffa.appspot.com",
  messagingSenderId: "1085949054457",
  appId: "1:1085949054457:web:3c62c91870b57c8ba4c128",
  measurementId: "G-WV0T0N9Z7W"
};

// Initialize Cloud Firestore through Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export interface User {
  isAdmin?: boolean;
}

const { Header, Content, Footer } = Layout;

const Brand = () => (<div className="logo" >Pink Dot 13 Admin</div>)

function App() {
  // const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(false);
  const [userData, setUserData] = useState<User>();

  useEffect(() => {
    // Listen to the Firebase Auth state and set the local state.
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (user) => {
        if (!user) {
          setIsLoggedIn(false);
          // history.push('/login');
          return;
        }

        setIsLoggedIn(true)

        const userDataQuery = firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid);

        try {
          const userDocument = await userDataQuery.get();
          const userData = userDocument.data();
          if (userData) {
            setUserData(userData);
          }
        } catch (error) {
          console.error('Error loading user data:', error);
          setUserData({});
          // history.push('/');
          return;
        }

        // Redirect if not admin.
        // if (!userData || !userData.isAdmin) {
        //   history.push('/');
        // }
      }
    );

    return () => {
      // Make sure we un-register Firebase observers when the component unmounts.
      unregisterAuthObserver();
    };
  }, []);

  const getFooter = () => {
    if (!isLoggedIn) {
      return (
        <Footer style={{ textAlign: 'center' }}>
          Pink Dot 13 ©2021
        </Footer>
      );
    }

    return (
      <Footer style={{ textAlign: 'center' }}>
        Pink Dot 13 ©2021 |
        <div className="loggedInUser">
          Logged in as {firebase.auth().currentUser!.email} <Button type="link" onClick={() => firebase.auth().signOut()}>Log out</Button>
        </div>
      </Footer>
    );
  }

  if (!isLoggedIn) {
    return (
      <Layout className="layout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Brand />
          <Menu  theme="dark" mode="horizontal" defaultSelectedKeys={['home']}>
            <Menu.Item key="home">Home</Menu.Item>
          </Menu>
        </Header>
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
          <Login />
        </Content>
        {getFooter()}
      </Layout>
    );
  }

  if (!userData || !userData.isAdmin) {
    return (
      <Layout className="layout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Brand />
          <Menu  theme="dark" mode="horizontal" defaultSelectedKeys={['home']}>
            <Menu.Item key="home">Home</Menu.Item>
          </Menu>
        </Header>
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
          <NoAccess />
        </Content>
        {getFooter()}
      </Layout>
    );
  }

  return (
    <Router>
      <Layout className="layout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Brand />
          <Menu  theme="dark" mode="horizontal">
            <Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
            <Menu.Item key="moderate"><Link to="/moderate">Moderate</Link></Menu.Item>
            <Menu.Item key="search"><Link to="/search">Search</Link></Menu.Item>
            <Menu.Item key="operations"><Link to="/operations">Operations</Link></Menu.Item>
            {/*<Menu.Item key="errors"><Link to="/errors">Error logs analysis</Link></Menu.Item>*/}
          </Menu>
        </Header>
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
          <Switch>
            {/*<Route path={"/errors"}>*/}
            {/*  <FailsAnalysis />*/}
            {/*</Route>*/}
            <Route path={"/operations"}>
              <Operations />
            </Route>
            <Route path={"/search"}>
              <Search />
            </Route>
            <Route path={"/moderate"}>
              <Moderate />
            </Route>
            <Route path={"/"}>
              <Home />
            </Route>
          </Switch>
        </Content>
        {getFooter()}
      </Layout>
    </Router>
  );
}

export default App;
