import React, { useEffect, useRef, useState } from "react"
import ReactDOMServer from 'react-dom/server';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import { Breadcrumb, Button, Descriptions, Input, Layout, Menu, notification, Switch, Select, message } from 'antd';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined, QuestionOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  // Circle,
  // CircleMarker,
  Map,
  Marker,
  // Polygon,
  Popup,
  TileLayer,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MapMarkerColor from '../components/MapMarkerColor';

export type Dot = {
  key: string;
  name: string;
  message: string;
  color: string;
  location: [number, number];
  created: number;

  isReviewed?: boolean;
  status?: string;
  randomGroup?: string;

  approvedBy?: string;
  approvedAt?: number;
  skippedBy?: string;
  skippedAt?: number;
  rejectedBy?: string;
  rejectedAt?: number;
  rejectedReason?: string;

  raw: any;
};

const groupOptions: string[] = [];
for (let i=0; i<20; i++) {
  groupOptions.push(`${i}`);
}

const convertDataToDot = (document: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
  const data = document.data();
  console.log('data:', data);

  let created = data.created;
  if ((data.created as firebase.firestore.Timestamp).toMillis) {
    created = (data.created as firebase.firestore.Timestamp).toMillis();
  }

  return {
    ...data,
    key: document.id,
    name: data.name,
    message: data.message,
    color: data.color,
    location: data.location,
    created,
    raw: data,
  } as Dot
}

type Position = [number, number];
type Viewport = {
  center: [number, number],
  zoom: number,
}

const center: Position = [1.3521, 103.8198];
const defaultViewport = {
  center,
  zoom: 11,
}

L.Icon.Default.imagePath='images/';

const incrementByOne = firebase.firestore.FieldValue.increment(1);
const decrementByOne = firebase.firestore.FieldValue.increment(-1);

const Moderate: React.FC = () => {

  const db = firebase.firestore();

  const [mode, setMode] = useState<string>("pending");
  const [currentItem, setCurrentItem] = useState<Dot>();
  const [rejectedReason, setRejectedReason] = useState<string>("");
  const [group, setGroup] = useState<number>(0);
  const mapRefreshTimer = useRef<number>()

  const loadNextItemForModeration = async (mode: string, groupRaw: number = -1) => {

    const randomGroup = groupRaw === -1 ? group : groupRaw;
    console.log(`Getting next item in group ${randomGroup} (groupRaw: ${groupRaw})`);

    setCurrentItem(undefined);
    setRejectedReason("");

    let newItem: Dot | null = null;

    let collection = db.collection("checkins");
    let query;

    switch (mode) {
      case "skipped":
        query = collection.where("status", "==", "skipped");
        break;
      case "approved":
        query = collection.where("status", "==", "approved");
        break;
      case "rejected":
        query = collection.where("status", "==", "rejected");
        break;
      default:
        query = collection.where("isReviewed", "==", false)
          .where("status", "==", "new");
    }

    query = query
      .where("randomGroup", "==", randomGroup)
      .orderBy("created", "asc")
      .limit(1);

    try {
      const snapshot = await query.get();
      // console.log('snapshot: ', snapshot);

      snapshot.forEach((document) => {
        newItem = convertDataToDot(document);
      });

    } catch (error) {
      console.error('Error when fetching next item: ', error);
    }

    if (newItem) {
      setCurrentItem(newItem);
      return;
    }
  }

  useEffect(() => {
    // Load initial items
    loadNextItemForModeration(mode);
  }, []);

  const goToMode = async (mode: string) => {
    setMode(mode);
    await loadNextItemForModeration(mode);
  }

  const handleApprove = async () => {
    if (!currentItem) {
      console.error("Tried to approve without active item.");
      return;
    }

    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    const updates = {
      isReviewed: true,
      status: "approved",
      approvedBy: firebase.auth().currentUser?.email || "unknown",
      approvedAt: timestamp,
    };
    const newDocument = {
      ...currentItem.raw,
      ...updates,
    };

    try {
      await db.collection("approved").doc(currentItem.key).set(newDocument);
      console.log('Added to approved queue');
    } catch (error) {
      console.error('Error adding to approved queue: ', error);
      notification.error({
        message: 'Could not add to approved queue',
        description: `Error: ${error}}`,
        duration: 0,
      });
      return;
    }

    try {
      await db.collection("checkins").doc(currentItem.key).update(updates)
      console.log('Updated checkin');

      notification.success({
        message: 'Successfully approved',
        description: '',
      });
    } catch (error) {
      console.error('Error updating check in:', error);
      notification.error({
        message: 'Could not approve',
        description: `Error: ${error}}`,
        duration: 0,
      });
      return;
    }
    await db.collection("stats").doc("general").update({
      approved: incrementByOne,
      pending: decrementByOne,
    });
    await db.collection("stats").doc("bucketBreakdown").update({
      [`${currentItem.raw.randomGroup}`]: decrementByOne,
    });

    await loadNextItemForModeration(mode);

    // Clear pending refresh if any
    if (mapRefreshTimer.current) {
      clearTimeout(mapRefreshTimer.current)
    }

    // Wait 10seconds before triggering refresh.
    mapRefreshTimer.current = window.setTimeout(async () => {
      const refreshMapFunction = firebase.functions().httpsCallable('refreshMap');
      const results: any = await refreshMapFunction();
      console.log('[refreshMap] results:', results);
      message.success(`Map refreshed with ${results.itemsCount} items.`);
    }, 10000);
  };

  const handleSkip = async () => {
    if (!currentItem) {
      console.error("Tried to skip without active item.");
      return;
    }

    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    const updates = {
      isReviewed: true,
      status: "skipped",
      skippedBy: firebase.auth().currentUser?.email || "unknown",
      skippedAt: timestamp,
    };

    try {
      await db.collection("checkins").doc(currentItem.key).update(updates)
      console.log('Updated checkin');

      notification.success({
        message: 'Successfully skipped',
        description: '',
      });
    } catch (error) {
      console.error('Error updating check in:', error);
      notification.error({
        message: 'Could not skip',
        description: `Error: ${error}}`,
        duration: 0,
      });
      return;
    }
    await db.collection("stats").doc("general").update({
      skipped: incrementByOne,
      pending: decrementByOne,
    });
    await db.collection("stats").doc("bucketBreakdown").update({
      [`${currentItem.raw.randomGroup}`]: decrementByOne,
    });

    await loadNextItemForModeration(mode);
  };

  const rejectItem = async (reason: string = "") => {
    if (!currentItem) {
      console.error("Tried to reject without active item.");
      return;
    }

    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    const updates = {
      isReviewed: true,
      status: "rejected",
      rejectedBy: firebase.auth().currentUser?.email || "unknown",
      rejectedReason: reason,
      rejectedAt: timestamp,
    };
    const newDocument = {
      ...currentItem.raw,
      ...updates,
    };

    try {
      await db.collection("rejected").doc(currentItem.key).set(newDocument);
      console.log('Added to rejected queue');
    } catch (error) {
      console.error('Error adding to rejected queue:', error);
      notification.error({
        message: 'Could not add to rejected queue',
        description: `Error: ${error}}`,
        duration: 0,
      });
      return;
    }

    try {
      await db.collection("checkins").doc(currentItem.key).update(updates)
      console.log('Updated checkin');

      notification.success({
        message: 'Successfully rejected',
        description: '',
      });
    } catch (error) {
      console.error('Error updating check in:', error);
      notification.error({
        message: 'Could not reject',
        description: `Error: ${error}}`,
        duration: 0,
      });
      return;
    }

    await db.collection("stats").doc("general").update({
      rejected: incrementByOne,
      pending: decrementByOne,
    });
    await db.collection("stats").doc("bucketBreakdown").update({
      [`${currentItem.raw.randomGroup}`]: decrementByOne,
    });

    await loadNextItemForModeration(mode);
  };

  const handleReject = async () => {
    if (!rejectedReason || rejectedReason.length === 0) {
      console.error("Tried to reject without reason.");
      return;
    }
    await rejectItem(rejectedReason);
  }

  const handleRejectTestingItems = async () => {
    const reason = rejectedReason || "For testing";
    await rejectItem(reason);
  }

  const getItemDetails = () => {
    if (!currentItem) {
      return null;
    }

    console.log('currentItem:', currentItem);
    const date = moment(currentItem.created).format("ddd, D MMM 'YY, h:mmA");
    const location = currentItem.location;

    const customIcon = L.divIcon({
      className: 'custom icon',
      html: ReactDOMServer.renderToString(<MapMarkerColor  color={currentItem.color} dotColor={"#FFEEF3"}  />),
      iconSize: L.point(30, 30),
    });

    return (
      <div>
        <Descriptions title={`Dot (${currentItem.key})`} bordered>
          <Descriptions.Item label="Name">{currentItem.name}</Descriptions.Item>
          <Descriptions.Item label="Color">{currentItem.color}</Descriptions.Item>
          <Descriptions.Item label="Created">{date.toString()}</Descriptions.Item>
          <Descriptions.Item label="Message" span={3}>{currentItem.message}</Descriptions.Item>
          <Descriptions.Item label="Is Reviewed?">{currentItem.isReviewed ? "Yes" : "No"}</Descriptions.Item>
          <Descriptions.Item label="Status">{currentItem.status}</Descriptions.Item>
          <Descriptions.Item label="Group">{currentItem.randomGroup}</Descriptions.Item>
        </Descriptions>

        <div style={{ marginTop: 32, maxWidth: 400, marginLeft: 'auto' }}>
          <Input.TextArea
            rows={3}
            value={rejectedReason}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setRejectedReason(event.target.value)}
            placeholder="Reason for rejection"
          />
        </div>

        <div style={{ marginTop: 32 }}>
          <Button
            type="primary"
            size="large"
            onClick={handleApprove}
            style={{ marginRight: 16 }}
          >
            Approve
          </Button>
          <Button
            size="large"
            onClick={handleSkip}
            style={{ marginRight: 16 }}
          >
            Skip
          </Button>

          <Button
            type="primary"
            size="large"
            danger
            onClick={handleReject}
            // disabled={rejectedReason.length === 0}
            style={{ float: 'right', marginLeft: 16 }}
          >
            Reject (Please fill in reason)
          </Button>

          <Button
            type="primary"
            size="large"
            danger
            onClick={handleRejectTestingItems}
            // disabled={rejectedReason.length === 0}
            style={{ float: 'right' }}
          >
            Reject (testing items)
          </Button>
        </div>

        <div className={"map"} style={{ marginTop: 32, maxWidth: 300 }}>
          <Map
            center={center}
            zoom={10}
            style={{ height: '200px' }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
            />
            <Marker position={location} draggable={false} icon={customIcon} />
            {/*<CircleMarker center={location} color="pink" radius={20}>*/}
            {/*  <Popup>You are here</Popup>*/}
            {/*</CircleMarker>*/}
          </Map>
        </div>
      </div>
    );
  }

  const onToggleShowSkipped = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  }

  const getHeading = () => {
    switch (mode) {
      case "pending":
        return "Moderate pending items";
      case "skipped":
        return "Moderate skipped items";
      case "approved":
        return "Moderate approved items";
      case "rejected":
        return "Moderate rejected items";
    }
    return "Moderate";
  }

  const handleChangeGroup = (newGroup: string) => {
    const newGroupNumber = parseInt(newGroup);
    setGroup(newGroupNumber);
    loadNextItemForModeration(mode, newGroupNumber);
  }

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Moderate</Breadcrumb.Item>
      </Breadcrumb>

      {/*<Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>*/}
      <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
        <Layout.Sider className="site-layout-background" width={200}>
          <Menu
            style={{ height: '100%' }}
            defaultSelectedKeys={[mode]}
            defaultOpenKeys={['sub1']}
            mode="inline"
          >
            <Menu.Item key="pending" icon={<ClockCircleOutlined />} onClick={() => goToMode("pending")}>
              Pending
            </Menu.Item>
            <Menu.Item key="skipped" icon={<QuestionOutlined />} onClick={() => goToMode("skipped")}>
              Skipped
            </Menu.Item>
            <Menu.Item key="approved" icon={<CheckOutlined />} onClick={() => goToMode("approved")}>
              Approved
            </Menu.Item>
            <Menu.Item key="rejected" icon={<CloseOutlined />} onClick={() => goToMode("rejected")}>
              Rejected
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>

          <h2>{getHeading()}</h2>

          <div style={{ float: 'right' }}>
            <span>Group:  </span>
            <Select defaultValue={`${group}`} style={{ width: 120 }} onChange={handleChangeGroup}>
              {groupOptions.map(g => (<Select.Option value={g} key={g}>{g}</Select.Option>))}
            </Select>
          </div>
          {getItemDetails()}

        </Layout.Content>
      </Layout>
      {/*<div className="site-layout-content">*/}
      {/*</div>*/}

    </>
  );
}

export default Moderate
