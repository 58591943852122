import firebase from "firebase";

export async function opWriteToLocalStorage() {
  const db = firebase.firestore();

  const index: any = {};

  const saveCollection = async (collection: string) => {
    const queryForItems = db.collection(collection);
    try {
      const snapshot = await queryForItems.get();

      const items: { [key: string]: any } = {};
      snapshot.forEach((document) => {
        items[document.id] = document.data();
      });

      index[collection] = items;

    } catch (error) {
      console.error('Error when querying: ', error);
      return;
    }
  }

  await saveCollection("checkins")
  await saveCollection("approved")
  await saveCollection("rejected")
  await saveCollection("tests")

  window.localStorage.setItem('items', JSON.stringify(index));
}
