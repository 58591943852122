import firebase from "firebase";
import moment from "moment";
import { message } from "antd";
import { download } from "../screens/Operations";

export async function opDownloadApprovedWithMetadata() {
  const db = firebase.firestore();
  const items: any[] = [];
  let i = 0;

  const queryForItems = db.collection("checkins").where("status", "==", "approved");
  try {
    const snapshot = await queryForItems.get();

    snapshot.forEach((document) => {
      const key = document.id;
      const data = document.data();

      let created = data.created;
      if ((created as firebase.firestore.Timestamp) && (created as firebase.firestore.Timestamp).toMillis) {
        created = (created as firebase.firestore.Timestamp).toMillis();
      }

      const location = data.location;
      const lat = location[0].toFixed(5);
      const lng = location[1].toFixed(5);

      items.push({
        n: data.name,
        m: data.message,
        d: `${key}|${lat}|${lng}|${data.color}|${created}`,
        approvedAt: data.approvedAt,
        approvedBy: data.approvedBy,
      });

      i++;
    });

  } catch (error) {
    console.error('Error when querying: ', error);
    return;
  }

  // Write to localStorage
  const content = JSON.stringify(items);
  window.localStorage.setItem('approved', content);

  // Download to file since we already have this data.
  const timeSuffix = moment().format("YYYYMMDD_HHmm");
  download(content, `approved_with_meta_${timeSuffix}.json`, 'text/plain');

  console.log(`Downloaded ${items.length} items.`);
  message.success(`Downloaded ${items.length} items.`);
}
