import firebase from "firebase";

export async function opAssignRandomBuckets() {
  const db = firebase.firestore();
  const keys: string[] = [];

  const queryForItems = db.collection("checkins").where("status", "==", "skipped");
  try {
    const snapshot = await queryForItems.get();

    snapshot.forEach((document) => {
      const key = document.id;
      const data = document.data();

      if (data.randomGroup) {
        // Skip.
        return;
      }
      keys.push(key);
    });

  } catch (error) {
    console.error('Error when querying: ', error);
    return;
  }

  if (keys.length === 0) {
    console.log('There are no checkins that need to be assigned a randomGroup.');
    return;
  }

  for (const key of keys) {
    const randomGroup = Math.floor(Math.random() * 20);
    try {
      await db.collection("checkins").doc(key).update({
        randomGroup,
      });
      console.info(`Doc ${key} assigned to group ${randomGroup}`);
    } catch (error) {
      console.error(`Doc ${key} could not be assigned to group ${randomGroup}`);
    }
  }
}
