import React from 'react';
import { Breadcrumb } from 'antd';

const NoAccess: React.FC = () => {
  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <h2>No access</h2>
        <p>Please get Ryan to grant you access</p>
      </div>
    </>
  );
}

export default NoAccess;
