import { getAllApprovedItems } from "../screens/Operations";

export const getAllApprovedItemsInBatches = async () => {

  let newItems;
  try {
    newItems = await getAllApprovedItems();
  } catch (error) {
    console.error('Error from getAllApprovedItems: ', error);
    throw error;
  }

  // Split into batches
  const batches: any[] = [];

  // Firebase cloudstore has a limit of 1mb per document, so that's about 5000 entries per doc.
  // const maxBatchSize = 1000;
  const maxBatchSize = 5000;
  for (let i = 0; i < newItems.length; i++) {
    const newItem = newItems[i];
    const batch = Math.floor(i / maxBatchSize);
    if (batches.length < batch + 1) {
      batches.push([]);
    }

    batches[batch].push(newItem);
  }
  console.log(`Approved items:${newItems.length}, batches: ${batches.length}`);

  return batches;
}
