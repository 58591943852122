import React, { useEffect, useState } from "react"
import { Breadcrumb, Button, Col, Row, Statistic } from 'antd';
import firebase from 'firebase';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {

  const [total, setTotal] = useState<number>();
  const [approved, setApproved] = useState<number>();
  const [rejected, setRejected] = useState<number>();
  const [skipped, setSkipped] = useState<number>();
  const [pendingReview, setPendingReview] = useState<number>();
  const [published, setPublished] = useState<number>();
  const [bucketBreakdown, setBucketBreakdown] = useState<{ bucket: string, count: number}[]>([]);

  useEffect(() => {
    // Listen to the stats doc.
    firebase.firestore().collection("stats").doc("general")
      .onSnapshot(function(doc) {
        const data = doc.data() || {};
        console.log("Current data: ", data);

        setTotal(data.total);
        setApproved(data.approved);
        setRejected(data.rejected);
        setSkipped(data.skipped);
        setPendingReview(data.pending);
        setPublished(data.published || 0);
      });

    firebase.firestore().collection("stats").doc("bucketBreakdown")
      .onSnapshot(function(doc) {
        const data = doc.data() || {};
        console.log("Current bucketBreakdown data: ", data);
        const bucketCounts: { [key: string]: number } = data;
        const bucketCountList = Object.keys(bucketCounts).map(key => ({ bucket: key, count: bucketCounts[key] }))
        setBucketBreakdown(bucketCountList);
      });

    //
    // firebase.firestore().collection("stats/general/bucketBreakdown").limit(100).onSnapshot((snapshot) => {
    //   const bucketCountList: { bucket: string, count: number }[] = []
    //   snapshot.forEach((doc) => {
    //     console.log('doc.data():', doc.data());
    //     bucketCountList.push({ bucket: `${doc.id}`, count: doc.data().count })
    //   })
    //   setBucketBreakdown(bucketCountList);
    // })

  }, []);

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Moderate</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <h1>Welcome</h1>

        <Row gutter={16} style={{ marginBottom: 64 }} justify="space-between">
          <Col span={4}>
            <Statistic title="Total" value={total} />
          </Col>
          <Col span={4}>
            <Statistic
              title="Published"
              value={published}
              valueStyle={{ color: '#3f8600' }}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Approved"
              value={approved}
              valueStyle={{ color: '#3f8600' }}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Rejected"
              value={rejected}
              valueStyle={{ color: '#cf1322' }}
            />
          </Col>
          <Col span={4}>
            <Statistic title="Skipped" value={skipped} />
          </Col>
          <Col span={4}>
            <Statistic title="Pending review" value={pendingReview} />
          </Col>
        </Row>

        <h2>Pending review breakdown by groups</h2>
        <div style={{ marginBottom: 64 }}>
          {bucketBreakdown.map(item => (
            <div>
              Group {item.bucket}: {item.count}
            </div>
          ))}
        </div>

        <h2>What's next?</h2>
        <p>Go to <Link to="/moderate">Moderate</Link> to start reviewing.</p>

      </div>
    </>
  );
};

export default Home;
