import firebase from "firebase";
import { notification } from "antd";

export async function opMoveSkippedToPending() {
  const db = firebase.firestore();
  const keys: string[] = [];

  const queryForItems = db.collection("checkins").where("status", "==", "skipped");
  try {
    const snapshot = await queryForItems.get();

    snapshot.forEach((document) => {
      const key = document.id;
      keys.push(key);
    });

  } catch (error) {
    console.error('Error when querying: ', error);
    notification.error({
      message: `Error when querying`,
      description: 'Check console for more details',
      duration: 0,
    });
    return;
  }

  if (keys.length === 0) {
    console.log('There are no skipped items that need to be updated.');

    notification.success({
      message: `There are no skipped items that need to be updated.`,
      description: '',
      duration: 0,
    });
    return;
  }

  for (const key of keys) {
    try {
      await db.collection("checkins").doc(key).update({
        status: "new",
        isReviewed: false,
      });
      console.info(`Doc ${key} status set to new`);
    } catch (error) {
      console.error(`Doc ${key} status could not be updated, error:`, error);
      notification.error({
        message: `Doc ${key} status could not be updated`,
        description: 'Check console for more details',
        duration: 0,
      });
    }
  }
}
