import firebase from "firebase";
import { message, notification } from "antd";

export async function refreshStats() {
  const refreshStatsFunction = firebase.functions().httpsCallable('refreshStats');

  const startTime = Date.now()
  const hide = message.loading('Refreshing...', 0)
  const results: any = await refreshStatsFunction();

  const endTime = Date.now()
  const timeTakenInSeconds = (endTime - startTime) / 1000
  hide()
  console.log('[refreshStats] results:', results);
  const successMessage = `Stats refreshed in ${timeTakenInSeconds.toFixed(2)} seconds.`

  notification.success({
    message: 'Success',
    description: successMessage,
    duration: 0,
  });
}
