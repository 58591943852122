import firebase from "firebase";
import { message } from "antd";

export async function rejectDot(dotKey: string) {
  await firebase.firestore().collection("checkins").doc(dotKey).update({
    isReviewed: true,
    status: 'rejected',
  });
  message.success(`Dot ${dotKey} rejected.`);
}
