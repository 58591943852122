import { getAllApprovedItemsInBatches } from "./opGetAllApprovedItemsInBatches";
import { message, notification } from "antd";
import moment from "moment";
import firebase from "firebase";
import { download } from "../screens/Operations";

export async function opWriteApprovedToStoreV2() {
  let batches;
  try {
    batches = await getAllApprovedItemsInBatches();
  } catch (error) {
    console.error('Error from getAllApprovedItemsInBatches: ', error);
    notification.error({
      message: `Could not get approved items in batches`,
      description: 'Check console for more details',
      duration: 0,
    });
    return;
  }

  // Write to localStorage
  const content = JSON.stringify(batches);
  window.localStorage.setItem('approved', content);

  // Download to file since we already have this data.
  const timeSuffix = moment().format("YYYYMMDD_HHmm");
  download(content, `approved_${timeSuffix}.json`, 'text/plain');


  if (batches.length === 0) {
    console.log('There are no batches to write.');
    return;
  }

  const db = firebase.firestore();
  let batchId = 0;
  let totalItems = 0;
  for (const items of batches) {
    totalItems += items.length;
    const content = JSON.stringify(items);
    try {
      await db.collection("published").doc(`batch${batchId}`).set({
        data: content,
      });
      console.info(`Batch ${batchId} updated with ${items.length} items.`);
      notification.success({
        message: `Batch ${batchId} updated with ${items.length} items.`,
        description: '',
        duration: 0,
      });
    } catch (error) {
      console.error(`Batch ${batchId} could not be updated. Error:`, error);
      notification.error({
        message: `Batch ${batchId} could not be updated. Please check console log for more details.`,
        description: '',
        duration: 0,
      });
    }
    batchId++;
  }

  message.success(`Written ${totalItems} items into ${batches.length} batches.`);
}
