import firebase from "firebase";
import moment from "moment";
import { download } from "../screens/Operations";

export async function opWriteApprovedToLocalStorage() {
  const db = firebase.firestore();
  const items: any[] = [];

  const queryForItems = db.collection("checkins").where("status", "==", "approved");
  try {
    const snapshot = await queryForItems.get();

    snapshot.forEach((document) => {
      const key = document.id;
      const data = document.data();

      let created = data.created;
      if ((created as firebase.firestore.Timestamp) && (created as firebase.firestore.Timestamp).toMillis) {
        created = (created as firebase.firestore.Timestamp).toMillis();
      }

      const location = data.location;

      items.push({
        key,
        name: data.name,
        message: data.message,
        location: `${location[0].toFixed(5)}|${location[1].toFixed(5)}`,
        created,
        color: data.color,
      });
    });

  } catch (error) {
    console.error('Error when querying: ', error);
    return;
  }

  console.log('Approved:', items);
  const content = JSON.stringify(items);
  window.localStorage.setItem('approved', content);

  const timeSuffix = moment().format("YYYYMMDD_HHmm");
  download(content, `approved_${timeSuffix}.json`, 'text/plain');
}
