import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, DatePicker, Form, message, Pagination, Row, Select, Spin, Table } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import { Dot } from './Moderate';
import moment, { Moment } from 'moment';

const groupOptions: string[] = ['any'];
for (let i=0; i<20; i++) {
  groupOptions.push(`${i}`);
}

const statusOptions: string[] = [
  "new",
  "approved",
  "rejected",
  "skipped",
];

const resultsSizeOptions: number[] = [
  5,
  10,
  20,
  50,
  100,
  500,
];

const statusToDateFieldMap: { [key: string]: string }= {
  new: "created",
  approved: "approvedAt",
  rejected: "rejectedAt",
  skipped: "skippedAt",
}

const statusToActorFieldMap: { [key: string]: string }= {
  new: "name",
  approved: "approvedBy",
  rejected: "rejectedBy",
  skipped: "skippedBy",
}

const statusToDateFilterLabelMap: { [key: string]: string }= {
  new: "Created on",
  approved: "Approved on",
  rejected: "Rejected on",
  skipped: "Skipped on",
}

const statusToActorFilterLabelMap: { [key: string]: string }= {
  approved: "Approved by",
  rejected: "Rejected by",
  skipped: "Skipped by",
}

const convertDataToRowItem = (document: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
  const data = document.data();
  console.log('data:', data);

  let created = data.created;
  if ((data.created as firebase.firestore.Timestamp).toMillis) {
    created = (data.created as firebase.firestore.Timestamp).toMillis();
  }

  let approvedAt = data.approvedAt;
  if (approvedAt && (approvedAt as firebase.firestore.Timestamp).toMillis) {
    approvedAt = (approvedAt as firebase.firestore.Timestamp).toMillis();
  }

  let skippedAt = data.skippedAt;
  if (skippedAt && (skippedAt as firebase.firestore.Timestamp).toMillis) {
    skippedAt = (skippedAt as firebase.firestore.Timestamp).toMillis();
  }

  let rejectedAt = data.rejectedAt;
  if (rejectedAt && (rejectedAt as firebase.firestore.Timestamp).toMillis) {
    rejectedAt = (rejectedAt as firebase.firestore.Timestamp).toMillis();
  }

  const dot: Dot = {
    ...data,
    key: document.id,
    name: data.name,
    message: data.message,
    color: data.color,
    location: data.location,
    randomGroup: data.randomGroup,
    created,

    approvedBy: data.approvedBy,
    approvedAt: approvedAt,
    skippedBy: data.skippedBy,
    skippedAt: skippedAt,
    rejectedBy: data.rejectedBy,
    rejectedAt: rejectedAt,

    raw: data,
  };

  return dot;
}

const Search: React.FC = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adminOptions, setAdminOptions] = useState<string[]>([]);

  const [actor, setActor] = useState<string>();
  const [status, setStatus] = useState<string>('approved');
  const [group, setGroup] = useState<string>('any');
  const [resultsSize, setResultsSize] = useState<number>(10);
  const [results, setResults] = useState<Dot[]>([]);
  const [dateRange, setDateRange] = useState<Moment[]>([]);

  const dateFilterLabelForStatus = statusToDateFilterLabelMap[status];
  const actorFilterLabelForStatus = statusToActorFilterLabelMap[status];

  const getAdminOptions = async () => {
    const db = firebase.firestore();
    const query = db.collection("users");

    const options: string[] = ['anyone'];

    try {
      const snapshot = await query.get();
      // console.log('snapshot: ', snapshot);

      snapshot.forEach((document) => {
        const data = document.data();
        if (data.isAdmin && data.email) {
          options.push(data.email);
        }
      });

    } catch (error) {
      console.error('Error when fetching next item: ', error);
    }

    setAdminOptions(options);
  }

  useEffect(() => {
    getAdminOptions();
  }, []);

  useEffect(() => {
    updateResults();
  }, [status, group, dateRange, actor, resultsSize]);

  const updateResults = async () => {
    setIsLoading(true);

    const db = firebase.firestore();
    const collection = db.collection("checkins");
    let query = collection.where("status", "==", status);

    if (group !== 'any') {
      query = query.where("randomGroup", "==", parseInt(group));
    }


    let filteringMode = status;
    const dateFieldName = statusToDateFieldMap[status];
    const actorFieldName = statusToActorFieldMap[status];
    if (dateRange && dateRange.length === 2) {
      console.log('dateRange:', dateRange);
      // Query by firestore timestamp
      if (dateRange[0]) {
        const startDate = firebase.firestore.Timestamp.fromDate(dateRange[0].toDate());
        query = query.where(dateFieldName, ">=", startDate);
      }
      if (dateRange[1]) {
        const endDate = firebase.firestore.Timestamp.fromDate(dateRange[1].toDate());
        query = query.where(dateFieldName, "<=", endDate);
      }
    }

    if (actor && actor !== 'anyone' && actorFieldName) {
      query = query.where(actorFieldName, "==", actor);
    }

    if (filteringMode === "approved") {
      query = query.orderBy("approvedAt", "desc");
    } else if (filteringMode === "rejected") {
      query = query.orderBy("rejectedAt", "desc");
    } else if (filteringMode === "skipped") {
      query = query.orderBy("skippedAt", "desc");
    } else {
      query = query.orderBy("created", "desc");
    }
    query = query.limit(resultsSize);


    const results: Dot[] = [];
    try {
      const snapshot = await query.get();
      // console.log('snapshot: ', snapshot);

      snapshot.forEach((document) => {
        const newItem: Dot = convertDataToRowItem(document);
        results.push(newItem);
      });

    } catch (error) {
      console.error('Error when querying resuls: ', error);
      message.error("Error when querying results. Please take a screenshot for Ryan.");
    }

    setResults(results);
    console.log('results:', results);
    setIsLoading(false);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus);
  }

  const handleChangeGroup = (newGroup: string) => {
    setGroup(newGroup);
  }

  const handleDateChange = (value: RangeValue<Moment>, dateString: [string, string]) => {
    console.log({value, dateString});
    setDateRange(value as [Moment, Moment]);
  }
  // function handleDateChangeOk(value: RangeValue<Moment>) {
  //   console.log('[handleDateChangeOk] value: ', value);
  //   setDateRange(value as [Moment, Moment]);
  // }

  const handleChangeResultsSize = (value: string) => {
    setResultsSize(parseInt(value));
  }

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Search</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <h2>Search {results.length > 0 && `(${results.length} results)`}</h2>
        <Row>
          <Col span={24}>
            <Form
              layout="inline"
              style={{ marginBottom: 16 }}
            >
              <Form.Item label="Status">
                <Select defaultValue={status} style={{ width: 120 }} onChange={handleChangeStatus}>
                  {statusOptions.map(g => (<Select.Option value={g} key={g}>{g}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="Group">
                <Select defaultValue={group} style={{ width: 120 }} onChange={handleChangeGroup}>
                  {groupOptions.map(g => (<Select.Option value={g} key={g}>{g}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="No. of results">
                <Select defaultValue={`${resultsSize}`} style={{ width: 120 }} onChange={handleChangeResultsSize}>
                  {resultsSizeOptions.map(g => (<Select.Option value={g} key={g}>{g}</Select.Option>))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              layout="inline"
              style={{ marginBottom: 16 }}
            >
              <Form.Item label={dateFilterLabelForStatus}>
                <DatePicker.RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={handleDateChange}
                  // onOk={handleDateChangeOk}
                />
              </Form.Item>
              {status != "new" && (
                <Form.Item label={actorFilterLabelForStatus}>
                  {adminOptions.length === 0 && <Spin/>}
                  {adminOptions.length > 0 && (
                    <Select defaultValue={actor} style={{ width: 120 }} onChange={(v) => setActor(v)}>
                      {adminOptions.map(g => (<Select.Option value={g} key={g}>{g}</Select.Option>))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>


        <Table
          loading={isLoading}
          dataSource={results.length === 0 ? undefined : results}
          bordered={true}
          pagination={{ defaultPageSize: 50 }}
          scroll={{ x: 'max-content', y: 500 }}
        >
          <Table.Column
            title="Name"
            dataIndex="name"
            key="name"
            width={120}
          />
          <Table.Column
            title="Message"
            dataIndex="message"
            key="message"
            width={240}
          />
          <Table.Column
            title="Status"
            dataIndex="status"
            key="status"
          />
          <Table.Column
            title="Key"
            dataIndex="key"
            key="key"
          />
          <Table.Column
            title="Created"
            dataIndex="created"
            key="created"
            render={value => (
              moment(value).format("D MMM, h:mm:ss a")
            )}
          />
          <Table.Column
            title="Group"
            dataIndex="randomGroup"
            key="randomGroup"
          />
          <Table.ColumnGroup title="Approved">
            <Table.Column
              title="Who"
              dataIndex="approvedBy"
              key="approvedBy"
            />
            <Table.Column
              title="When"
              dataIndex="approvedAt"
              key="approvedAt"
              render={value => {
                if (value) {
                  return moment(value).format("D MMM, h:mm:ss a")
                }
                return "-"
              }}
            />
          </Table.ColumnGroup>
          <Table.ColumnGroup title="Rejected">
            <Table.Column
              title="Who"
              dataIndex="rejectedBy"
              key="rejectedBy"
            />
            <Table.Column
              title="When"
              dataIndex="rejectedAt"
              key="rejectedAt"
              render={value => {
                if (value) {
                  return moment(value).format("D MMM, h:mm:ss a")
                }
                return "-"
              }}
            />
          </Table.ColumnGroup>
        </Table>

      </div>
    </>
  );
}

export default Search;
